<template>
    <div>
        <div v-if="recommendedProducts().length > 0" class="mt-3 mb-3">
            <h5 class="pl-2">{{ $t('recommendMenu') }} </h5>
            <div class="recommend-container mt-2">
                <div v-for="(item, index) in recommendedProducts()" :key="index" class="col-4 pr-2 pl-2">
                    <div @click="productDetailHandler(item)" :data-toggle="isAllowToShowDetailModal(item)"
                        data-target="#reccomendModal">
                        <div class="square-box shadow-menu" :style="{
            'background-image': `url('${noImg}')`,
            width: '100%',
            'background-size': 'cover',
            'background-position': 'center center',
        }"><img class="image-inbox" :src="getImageURL(item.remoteImagePath, '200')"
                                @error="setErrorImg($event, item.remoteImagePath)">
                            <span v-if="!isAllowToOrder(item)" class="font-weight-bold add-to-cart-badges-nostock">
                                <h4 class="mt-2 text-center">{{ $t('outOfStock') }}</h4>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="mt-2 h5 autoShowHide">
                            {{ item.ProductSKU.SKUName }}
                        </div>
                        <span>
                            <span hidden>
                                X
                            </span>
                        </span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <h6 class="text-grey pl-1 pb-3">
                            ฿ {{ item.ProductPRU[0].exceedUc }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="reccomendModal" style="z-index: 9999; " data-backdrop="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div style="z-index: 1080;" class="close " data-dismiss="modal">
                            <div class="d-flex justify-content-end "> <img width="10%" src="/img/close.png">
                            </div>
                        </div>
                        <detail v-bind:cartTotal="cartTotal" v-bind:SKUObjectId="SKUObjectId"
                            @update-cartTotal="updateCartTotal">
                        </detail>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Detail from './AppProductDetails'
import util from '@/util/util'
import poscrm from '@/services/poscrm'

export default {
    components: {
        Detail,
    },
    data() {
        return {
            SKUObjectId: '',
            cartTotal: 0,
            products: []
        }
    },
    computed: {
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    created() {
        this.getProduct()
    },
    methods: {
        ...util,
        async getProduct(page = 1) {
            const uid = localStorage.getItem('uid')
            const categoryObjectId = ''
            let params = {
                shopObjectId: localStorage.getItem('shopObjectId'),
                page: page,
                limit: 50,
            }
            if (categoryObjectId !== '') {
                params['categoryObjectId'] = categoryObjectId
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v1.1/' + uid + '/Shop/getproduct/view',
                    params: params,
                }).then((res) => {
                    this.products = res.data?.data
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        updateCartTotal(total) {
            this.cartTotal = total
            this.$emit('update-cartTotal', total)
            this.$forceUpdate()
        },
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        recommendedProducts() {
            return this.products.filter(product => product.ProductSKU.isRecommend === true);
        },
        productDetailHandler(product) {
            this.trackButtonClick('menulist/ProductDetail(RecommendMenu)')
            this.SKUObjectId = product.ProductSKU.objectId

        },
        isAllowToShowDetailModal(product) {
            if (product.ProductSKU.skuType !== 'P') {
                return 'modal'
            }
            if (product.ProductSKU.skuType == 'P' && this.isAllowToOrder(product) == true) {
                return 'modal'
            }
        },
        isAllowToOrder(product) {
            if (product.ProductSKUStock.onhandQty > product.ProductSKUStock.notAllowMinQty) {
                return true
            }
            else if (product.ProductSKUStock.notAllowMinQty == null) {
                return true
            }
            else {
                return false
            }
        },
    },
}
</script>
