<template>
    <div>
        <CRow v-if="OrderType == 'delivery'">
            <CCol>
                <span class="toggle" v-c-emit-root-event:toggle-sidebar-mobile>
                    <i class="fi fi-rr-menu-burger"></i>
                </span>
            </CCol>
            <CCol style="text-align: end;">
                <router-link to="/delivery/mycart">
                    <span class="callStaff ">
                        <i class="fi fi-rr-shopping-cart"></i>
                    </span>
                </router-link>
            </CCol>
        </CRow>
        <div class="row" v-else>
            <div class="col-3">
                <span class="toggle" v-c-emit-root-event:toggle-sidebar-mobile>
                    <i class="fi fi-rr-menu-burger"></i>
                </span>
            </div>
            <div class="text-center pt-2 col-6">
                <div class="h4 text-dark table-name  font-weight-bold">
                    <span>
                        {{ tableName }}
                    </span>
                </div>
            </div>
            <div class="col-3" style="text-align: end;">
                <span class="callStaff" @click="() => { callStaffModal = true }">
                    <img src="https://cdn-icons-png.flaticon.com/512/3239/3239952.png" class="img-fluid pb-2"
                        style="width: 50%; filter: invert(100%);">
                </span>
            </div>
        </div>

        <div class="mt-4">
            <h3>{{ greetingMessage1 }}</h3>
            <p class="text-description">{{ greetingMessage2 }}</p>
        </div>
        <!-- Call Staff Modal -->
        <call-staff v-bind:callStaffModal="callStaffModal" @update-status="callStaffModal = false"></call-staff>
    </div>
</template>

<script>
import poscrm from '@/services/poscrm'
import Detail from './AppProductDetails'
import CallStaff from '../views/dine-in/CallStaff'
import '@/util/menu.css'

export default {
    components: {
        Detail,
        CallStaff,
    },
    data() {
        return {
            greetingMessage1: '',
            greetingMessage2: '',
            callStaffModal: false,
            orderShopSettingCollection: {},
        }
    },
    created() {
        this.getOrderShopSetting()
    },
    computed: {
        OrderType() {
            let type = window.location.pathname
            if (type === '/delivery/menulists') {
                return 'delivery'
            } else {
                return 'qr'
            }
        },
        tableName() {
            if (localStorage.getItem('table')) {
                let table = JSON.parse(localStorage.getItem('table')) || {}
                return table.name
            } else {
                return ''
            }
        },

    },
    methods: {
        async getOrderShopSetting() {
            const uid = `${localStorage.getItem('uid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            let params = {
                shopObjectId: shopObjectId,
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v2.1/' + uid + '/OrderShopSetting',
                    params: params,
                }).then((res) => {
                    this.orderShopSettingCollection = res.data?.data?.[0] || null
                    this.greetingMessage1 = this.orderShopSettingCollection?.greetingMessage[0] || ''
                    this.greetingMessage2 = this.orderShopSettingCollection?.greetingMessage[1] || ''
                })
            } catch (error) {
                throw new Error(error)
            }
        },
    },
}
</script>
<style>
.table-name {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
