<template>
    <div class="row menu-panel">
        <div class="col">
            <!-- No product available -->
            <div v-if="products.length === 0" class="text-center mt-5">
                <img alt="" class="img-fluid" width="30%" src="https://cdn-icons-png.flaticon.com/512/776/776495.png"
                    onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';" />
                <p class="mt-3 font-weight-bold">{{ $t('noPreduct') }}</p>
            </div>
            <div v-else>
                <!-- Product Available -->
                <div v-if="isSelfOrderEnabled === true" class="row p-2">
                    <div v-if="display === 'list'" class="col p-0">
                        <table class="mb-3" aria-describedby="" style="width: 100%">
                            <tr v-for="product in products" :key="product.objectId"
                                :style="{ 'border-bottom': '1px solid rgba(246, 246, 246)', opacity: isAllowToOrder(product) ? 1 : 0.5 }"
                                @click="productDetailHandler(product)" :data-toggle="isAllowToShowDetailModal(product)"
                                data-target="#detailModal">
                                <td style="width: 25%; padding: 7px">
                                    <!-- Product No Images in List Menu -->
                                    <div v-if="!product.remoteImagePath" class="square-box shadow-menu"
                                        :style="{ 'background-color': getImgColor(product.indexColor), width: '100%' }">
                                        <span v-if="!isAllowToOrder(product)"
                                            class="font-weight-bold add-to-cart-badges-nostock">
                                            <h4 class="mt-2 text-center">{{ $t('outOfStock') }}</h4>
                                        </span>
                                    </div>
                                    <!-- Product With Images in List Menu -->
                                    <div v-else class="square-box shadow-menu" :style="{ width: '100%' }">
                                        <img v-lazy="getImageURL(product.remoteImagePath, '100')" class="image-inbox">
                                        <span v-if="!isAllowToOrder(product)"
                                            class="font-weight-bold add-to-cart-badges-nostock">
                                            <h4 class="mt-2 text-center">{{ $t('outOfStock') }}</h4>
                                        </span>
                                    </div>
                                </td>
                                <td valign="top" class="p-2">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <div class="h5 autoShowHide">{{ product.SKUName }}</div>
                                            <h6 class="text-grey">฿{{ product.ProductPRU[0].exceedUc }}</h6>
                                        </div>
                                        <div class="text-right d-flex align-items-center">
                                            <div class="pt-3 text-center">
                                                <span v-if="quantityShow(product.ProductPLU) > 0"
                                                    style="font-size: medium;border-radius: 50%;width: 25px;aspect-ratio: 1/1;"
                                                    class="bg-color-theme  font-weight-bold badge">
                                                    {{ quantityShow(product.ProductPLU) }}
                                                </span><br>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>

                    </div>
                    <!-- Display Product in Grid Mode -->
                    <div class="col-6 pr-2 pl-2" v-else-if="display === 'grid'" v-for="product in products"
                        :key="product.objectId">
                        <div @click="productDetailHandler(product)" :data-toggle="isAllowToShowDetailModal(product)"
                            data-target="#detailModal">
                            <!-- Product has no Image -->
                            <div v-if="product.remoteImagePath === null ||
                product.remoteImagePath === undefined
                " class="square-box shadow-menu" :style="{
                'background-color': getImgColor(product.indexColor),
                width: '100%',
            }">
                                <span class="font-weight-bold badge add-to-cart-badges">
                                </span>
                                <span v-if="getTotalQuantity(product.ProductPLU[0].id) == 0"
                                    class="font-weight-bold bg-color-theme  badge add-to-cart-badges-noqty">
                                    +
                                </span>
                                <span v-else class="font-weight-bold badge add-to-cart-badges">
                                    {{ getTotalQuantity(product.ProductPLU[0].id) }}
                                </span>
                            </div>
                            <!-- Product has Image -->
                            <div v-else v-lazy:background-image="getImageURL(product.remoteImagePath, '300')"
                                class="square-box shadow-menu" :style="{
                width: '100%',
                'background-size': 'cover',
                'background-position': 'center center',
            }">
                                <div v-if="product.skuType !== 'P'">
                                    <span v-if="getTotalQuantity(product.ProductPLU[0].id) == 0"
                                        class="font-weight-bold bg-color-theme  badge add-to-cart-badges-noqty">
                                        +
                                    </span>
                                    <span v-else class="font-weight-bold badge add-to-cart-badges">
                                        {{ getTotalQuantity(product.ProductPLU[0].id) }}
                                    </span>
                                </div>
                                <div v-else>
                                    <span
                                        v-if="getTotalQuantity(product.ProductPLU[0].id) == 0 && isAllowToOrder(product)"
                                        class="font-weight-bold bg-color-theme   badge add-to-cart-badges-noqty">
                                        +
                                    </span>
                                    <span v-else-if="!isAllowToOrder(product)"
                                        class="font-weight-bold add-to-cart-badges-nostock">
                                        <h4 class="mt-2 text-center">{{ $t('outOfStock') }}</h4>
                                    </span>
                                    <span v-else class="font-weight-bold badge add-to-cart-badges">
                                        {{ getTotalQuantity(product.ProductPLU[0].id) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="mt-2 h5 autoShowHide">
                                {{ product.SKUName }}
                            </div>
                            <span>
                                <span hidden>
                                    {{ (qshow = getTotalQuantity(product.ProductPLU[0].id)) }}
                                </span>
                                <span v-if="qshow > 0" class="mt-2 font-weight-bold badge badge-success">
                                </span>
                            </span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <h6 class="text-grey pl-1 pb-3">
                                ฿{{ product.ProductPRU[0].exceedUc }}
                            </h6>
                        </div>
                    </div>
                </div>
                <div v-if="isSelfOrderEnabled !== true" class="row p-2">
                    <div v-if="display === 'list'" class="col p-0" style="opacity: 0.5">
                        <table class="mb-3" aria-describedby="" style="width: 100%">
                            <tr v-for="product in products" :key="product.objectId"
                                style="border-bottom: 1px solid rgba(246, 246, 246)">
                                <td style="width: 25%; padding: 7px">
                                    <div v-if="product.remoteImagePath === null ||
                product.remoteImagePath === undefined
                " class="square-box shadow-menu" :style="{
                'background-color': getImgColor(product.indexColor),
                width: '100%',
            }"></div>
                                    <div v-else class="square-box shadow-menu" :style="{
                'background-image': `url('${getImageURL(product.remoteImagePath, '100')}')`,
                'background-size': 'cover',
                width: '100%',
            }"></div>
                                </td>
                                <td valign="top" class="p-2">
                                    <div class="d-flex justify-content-between">
                                        <div class="h6 text-grey autoShowHide">
                                            {{ product.SKUName }}
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <h6 class="text-black">
                                            <strong>฿{{ product.ProductPRU[0].exceedUc }}</strong>
                                        </h6>
                                        <small>
                                            {{ product.unit.name }}
                                        </small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-6 pr-2 pl-2" v-else-if="display === 'grid'" v-for="product in products"
                        :key="product.objectId" style="opacity: 0.5">
                        <div v-if="product.remoteImagePath === null ||
                product.remoteImagePath === undefined
                " class="square-box shadow-menu" :style="{
                'background-color': getImgColor(product.indexColor),
                width: '100%',
            }"></div>
                        <div v-else class="square-box shadow-menu" :style="{
                'background-image': `url('${product.remoteImagePath}')`,
                'background-size': 'cover',
                width: '100%',
            }"></div>
                        <div class="d-flex justify-content-between">
                            <div class="mt-2 h6 text-grey autoShowHide">
                                {{ product.SKUName }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <h6 class="text-black pb-3">
                                <strong>฿{{ product.ProductPRU[0].exceedUc }}</strong>
                            </h6>
                            <small>{{ product.unit.name }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="detailModal" style="z-index: 9999;" data-backdrop="false">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div style="z-index: 1080;" class="close " data-dismiss="modal">
                            <div class="d-flex justify-content-end "> <img width="10%" src="/img/close.png">
                            </div>
                        </div>
                        <detail v-bind:cartTotal="cartTotal" v-bind:SKUObjectId="SKUObjectId"
                            @update-cartTotal="updateCartTotal">
                        </detail>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import util from '@/util/util'
import '@/util/menu.css'
import Detail from './AppProductDetails'

export default {
    props: ['categoryObjectId', 'searchVal', 'display'],
    components: {
        Detail,
    },
    data() {
        return {
            products: [],
            categoryName: '',
            SKUObjectId: '',
            cartTotal: 0,
            orderCompleted: Boolean,
        }
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
        isSelfOrderEnabled() {
            let enabled = this.shop.delivery.isSelfOrderEnabled
            if (enabled !== true) {
                sessionStorage.clear()
                localStorage.clear()
                window.location.href = '/texp'
            }
            return enabled
        },
        shop() {
            return this.shopLineOA.find(
                (detail) => detail.objectId === this.shopObjectId
            )
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    mounted() {
        $('#detailModal').on('shown.bs.modal', () => {
            // console.log('The modal is now shown')
            document.body.style.overflow = 'hidden';
            const position = sessionStorage.getItem('scrollPosition');
            window.scrollTo(0, -1);
            setTimeout(() => {
                window.scrollTo(0, position);
            }, 500);
        })
        $('#detailModal').on('hidden.bs.modal', () => {
            // console.log('The modal is now hidden')
            const postion = sessionStorage.getItem('scrollPosition')
            window.scrollTo(0, postion)
            document.body.style.overflow = ''
        })
    },
    beforeDestroy() {
        $('#detailModal').off('shown.bs.modal');
        $('#detailModal').off('hidden.bs.modal');
    },
    created() {
        this.getProduct()
        this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
        this.getReceiptTransaction()
    },
    methods: {
        ...util,
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        getTotalQuantity(productId) {
            const cart = JSON.parse(sessionStorage.getItem('cart')) || {}
            let result = 0
            if (cart.length > 0) {
                result = cart
                    .filter(product => product.productPLU.id === productId)
                    .reduce((total, product) => {
                        // Check if product.quantity is a number and defined
                        const quantity = typeof product.quantity === 'number' ? product.quantity : 0;
                        return total + quantity;
                    }, 0); // Initialize the accumulator to 0
            }
            return result
        },
        isAllowToShowDetailModal(product) {
            if (product.skuType !== 'P') {
                return 'modal';
            }
            else if (product.skuType == 'P' && this.isAllowToOrder(product) == true) {
                return 'modal';
            }
        },
        productDetailHandler(product) {
            this.trackButtonClick('menulist/ProductDetail(Normal Menu)');
            this.SKUObjectId = product.objectId
            sessionStorage.setItem('scrollPosition', window.scrollY)

        },
        isAllowToOrder(product) {
            if (product.ProductSKUStock.onhandQty > product.ProductSKUStock.notAllowMinQty) {
                return true
            }
            else if (product.ProductSKUStock.notAllowMinQty == null) {
                return true
            }
            else {
                return false
            }
        },
        setErrorImg(event, image) {
            event.target.src = this.noImg;
        },
        quantityShow(productPLUId) {
            let quantity = 0;
            for (let item in productPLUId) {
                const locationInCart = this.cartLists.findIndex((p) => {
                    return p.productPLUId === productPLUId[item].id;
                });
                if (locationInCart !== -1) {
                    quantity += this.cartLists
                        .filter((i) => i.productPLUId === productPLUId[item].id)
                        .reduce((a, b) => a + b.quantity, 0);
                }
            }
            return quantity;
        },
        updateCartTotal(total) {
            this.cartTotal = total;
            this.$emit('update-cartTotal', total);
            this.$forceUpdate();
        },
        getImgColor(indexColor) {
            return util.generateColor(indexColor);
        },
        async getProduct(page = 1) {
            const uid = this.uid;
            const categoryObjectId = this.categoryObjectId;
            let params = {
                shopObjectId: this.shopObjectId,
                page: page,
                limit: 50,
                searchVal: this.searchVal,
            };
            if (categoryObjectId !== '') {
                params['categoryObjectId'] = categoryObjectId;
            }

            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v1.1/' + uid + '/Shop/getproduct/menu',
                    params: params,
                }).then((res) => {
                    let response = {}
                    response = res?.data?.data || {}
                    this.products = response
                });
            } catch (error) {
                throw new Error(error);
            }
        },
        async getReceiptTransaction() {
            let receiptObjectId = localStorage.getItem('receiptObjectId') || null;

            if (receiptObjectId != null) {
                try {
                    await poscrm
                        .get('/api/v1.1/' + this.uid + '/Receipt/data/' + receiptObjectId)
                        .then((res) => {
                            if (res.data?.data?.document != null) {
                                let document = {}
                                document = res.data.data.document;
                                if (document.receiptStatus === 'A') {
                                    this.orderCompleted = true;
                                    localStorage.clear();
                                    sessionStorage.clear();
                                } else {
                                    const table = document.table || [];
                                    const paymentStatus = table.tableStatus || '';

                                    if (paymentStatus == 'P') {
                                        this.$router.push('/dine-in/pending/payment');
                                    }
                                }
                            } else {
                                localStorage.clear();
                                sessionStorage.clear();
                                this.$router.push('/texp');
                            }
                        });
                } catch (error) {
                    throw new Error(error);
                }
            } else {
                let uid = localStorage.getItem('uid');
                if (uid == null) {
                    this.orderCompleted = true;
                }
            }
        },
    },

    watch: {
        categoryObjectId() {
            this.getProduct()
        },
        searchVal(newVal, oldVal) {
            this.searchVal = newVal
            this.getProduct()
        },
    },
}
</script>

<style scoped>
.modal-body {
    padding: 0rem;
}
</style>
