<template>
  <div class="text-black ">
    <div class="text-center" v-if="orderCompleted === true" style="margin-top: 150px">
      <img src="https://cdn-icons-png.flaticon.com/512/4594/4594686.png" class="img-fluid" width="50%" alt="" />
      <h4 class="mt-5 font-weight-bold text-dark">{{ $t('endOfTransection') }}</h4>
      <span class="text-description">{{ $t('endOfTransectionDialog') }}</span>
    </div>
    <div v-else>
      <Header></Header>
      <Banner @update-cartTotal="updateCartTotal"></Banner>
      <RecommendProducts @update-cartTotal="updateCartTotal"></RecommendProducts>
      <AppMenulists ref="appMenulists"></AppMenulists>

      <div class="modal fade" id="detail-modal" data-backdrop="static">
        <div class="modal-dialog" role="document">
          <div style="background-color: unset;" class="modal-content p-3">
            <div>
              <div class="fixed-bottom">
                <div class="p-4 bottom-modal m-2">
                  <h3 class="mt-2 text-dark">{{ $t('termOfService') }}</h3>
                  <div class="text-grey mb-5" style="font-size: large;margin-top: 1cap" v-html="formattedPolicy"></div>
                  <CButton @click="setPolicy('agreed')" block data-dismiss="modal" class="btn-color-theme ">{{
      $t('accept') }}</CButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppPushNotification ref="notification" :title="notificationModel.title" :text="notificationModel.text"
        :color="notificationModel.color" />

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import '@/util/menu.css'
import Banner from '@/components/AppBanners'
import Header from '@/components/AppHeader'
import RecommendProducts from '@/components/AppRecommendProducts'
import AppMenulists from '@/components/AppMenulists'
import poscrm from '@/services/poscrm'
import AppPushNotification from '@/components/AppPushNotification'

export default {
  components: {
    AppMenulists,
    RecommendProducts,
    Header,
    Banner,
    AppPushNotification
  },
  data() {
    return {
      products: [],
      categories: [],
      cartLists: [],
      cartTotal: 0,
      orderCompleted: false,
      searchVal: '',
      showNavbar: false,
      reccomendProduct: [],
      SKUObjectId: '',
      items: [],
      greetingMessage1: '',
      greetingMessage2: '',
      orderShopSettingCollection: [],
      policyDescription: '',
      notificationModel: {
        color: '',
        title: '',
        text: '',
      },
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    shopName() {
      if (this.shop) {
        return this.shop.shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shop) {
        return this.shop.remoteImagePath
      } else {
        return ''
      }
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    formattedPolicy() {
      return this.policyDescription.replace(/\n/g, '<br>');
    },
  },
  created() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getCartTotal()
    this.getOrderShopSetting()
  },
  methods: {
    ...util,
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    showNotification(title, text, color) {
      this.notificationModel = {
        color: color,
        title: title,
        text: text,
      }
      this.$refs.notification.pushNotification();
    },
    async getOrderShopSetting() {
      const uid = `${localStorage.getItem('uid')}`
      const shopObjectId = `${localStorage.getItem('shopObjectId')}`

      let params = {
        shopObjectId: shopObjectId,
      }
      try {
        await poscrm({
          method: 'get',
          url: '/api/v2.1/' + uid + '/OrderShopSetting',
          params: params,
        }).then((res) => {
          this.policyDescription = res.data?.data[0]?.policyDescription || ''
          if (!localStorage.getItem('policyAgreed') && this.policyDescription !== '') {
            $('#detail-modal').modal('show')
          }
          if (this.cartLists.length > 0) {
            this.showNotification(this.$t('hasItemInBasket'), this.$t('hasItemInBasketDespt'), 'info')
          }

        })
      } catch (error) {
        throw new Error(error)
      }
    },

    updateCartTotal(total) {
      this.$refs.appMenulists.getCartTotal();
      this.cartTotal = total
      this.$emit('update-cartTotal', total)
      this.$forceUpdate()
    },
    updateTotalCost() {
      this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.totalAmount
      }, 0)

      this.totalCost = util.convertCurrency(total)
      this.componentKey += 1
    },
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    setPolicy(text) {

      localStorage.setItem('policyAgreed', true)
    }
  },
  watch: {
    cartTotal(newVal, oldVal) {
      this.cartTotal = newVal
      this.updateTotalCost()
    },
  },
}
</script>

<style>
.custom-select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Optional: If you want to horizontally center the text */

}

.navbar {
  height: fit-content;
  width: 100%;
  background: hsl(0, 0%, 100%);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.navbar.navbar--hidden {
  visibility: hidden;
}

.recommend-container {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: inline-flex;
}

.recommend-btn {
  height: 100px;
  width: 100px;
  font-size: medium;
  font-weight: lighter;
  border-radius: 10px;
  background-color: rgba(127, 232, 202, 0.3);
  text-align: center;
  margin: 3px;
  margin-bottom: 10px;
}

.category-container {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: inline-flex;

}





.toggles {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  width: 60px;
  height: 40px;
  background-color: #e5f1e5;
  color: #2d8c28;
  font-size: large;
}

.hearder {
  width: 100%;
}



.category-row {
  margin: 10px;
}

.searchbox-header {
  font-size: 13px;
  border-radius: 10px;
  line-height: 1.5;
  padding: 5px 10px;
  border: 2px solid #eceff0;
  color: rgb(14, 14, 16);
  background: #eceff0;
  display: block;
  height: 45px;
  width: 100%;
  padding-left: 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 10px center;
}

.searchbox {
  font-size: 13px;
  border-radius: 10px;
  line-height: 1.5;
  padding: 5px 10px;
  transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
  border: 2px solid #eceff0;
  color: rgb(14, 14, 16);
  background: #eceff0;
  display: block;
  height: 50px;
  width: 100%;

  :hover {
    border-color: #ccc;
  }

  :focus {
    border-color: #9147ff;
    background: #fff;
  }
}

.banner {
  border-radius: 10px;
  aspect-ratio: 2 / 1;
}

@media screen and (max-width: 376px) {
  .category-row {
    margin: 0px;
  }
}

img.image-inbox {
  position: absolute;
  object-fit: cover;
}

.modal-body {
  padding: 1rem;
}
</style>
